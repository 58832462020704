import sendRequest from "@/libs/sendRequest";
import {products} from "@/store/modules/products";

export const points_product = {
    namespaced: true,
    state: {
        products: [],
        product: {},
        error: false,
    },
    mutations: {
        setProducts: (state, payload) => (state.products = payload),
        setProduct: (state, payload) => (state.product = payload),
        setError: (state, payload) => (state.error = payload),
    },
    getters: {
        getProducts: (state) => (state.products),
        getProduct: (state) => (state.product),
        getError: (state) => (state.error),
    },
    actions: {
        list: async({commit, rootGetters}, data) =>{
            let custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/wikspoints/products/list', 'GET', null, null, null, custom_headers)
            
            if(response.success && response.data) {
                
                let raw_products = response.data.list ?? [];
                
                let products = raw_products.map((product) => {
                    return {
                        ...product,
                    }
                });
                
                commit('setProducts', raw_products.length > 0 ? products : []);
            }

            else if(!response.success && response.errors) {
                console.log(response.errors);
            }

            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API');
            }
        },
        view: async({commit, rootGetters}, id) =>{
            let custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/wikspoints/products/view', 'GET', null, null, {id: id}, custom_headers)

            if(response.success && response.data) {
                commit('setProduct', response.data.list ?? {});
            }

            else if(!response.success && response.errors) {
                console.log(response.errors);
            }

            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API');
            }
        },
        add: async({commit, rootGetters}, data) =>{
            let custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/wikspoints/products/add','POST', null, data, null, custom_headers)

            if(response.success && response.data) {
                commit('setAlert',
                    {   open: true,
                        variant: 'success',
                        message: 'Producto añadido con éxito.'},
                    {root: true})
                commit('setError', false);

            }

            else if(!response.success && response.errors) {
                let messages = [];

                for(let error of response.errors) {
                    switch(error.code) {
                        case 'wrong_or_empty_porcentaje':
                            messages.push("Categoria requerida");
                            break;
                        case 'wrong_or_empty_inicio':
                            messages.push("Nombre requerido");
                            break;
                        case 'wrong_or_empty_tipo':
                            messages.push("Tipo requerido");
                            break;
                        case 'wrong_or_empty_descripcion':
                            messages.push("Descripción requerido");
                            break;
                        case 'wrong_or_empty_stock':
                            messages.push("stock requerido");
                            break;
                        case 'wrong_or_empty_imagen':
                            messages.push("imagen requerido");
                            break;
                        default:
                            messages.push("Un error ha ocurrido intente más tarde");
                            break;
                    }
                }

                commit('setAlert',
                    {   open: true,
                        variant: 'danger',
                        message: messages},
                    {root: true})
                commit('setError', true);
            }

            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API');
            }
        },
        update: async({commit, rootGetters}, data) =>{
            let custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/wikspoints/products/update','PUT', null, data.body, data.query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert',
                    {   open: true,
                        variant: 'success',
                        message: 'Producto editado con éxito.'},
                    {root: true})
                commit('setError', false)
            }

            else if(!response.success && response.errors) {
                let messages = [];

                for(let error of response.errors) {
                    switch(error.code) {
                        case 'wrong_or_empty_porcentaje':
                            messages.push("Categoria requerida");
                            break;
                        case 'wrong_or_empty_inicio':
                            messages.push("Nombre requerido");
                            break;
                        case 'wrong_or_empty_tipo':
                            messages.push("Tipo requerido");
                            break;
                        case 'wrong_or_empty_descripcion':
                            messages.push("Descripción requerido");
                            break;
                        case 'wrong_or_empty_stock':
                            messages.push("stock requerido");
                            break;
                        case 'wrong_or_empty_imagen':
                            messages.push("imagen requerido");
                            break;
                        default:
                            messages.push("Un error ha ocurrido intente más tarde");
                            break;
                    }
                }

                commit('setAlert',
                    {   open: true,
                        variant: 'danger',
                        message: messages},
                    {root: true})
                commit('setError', true);
            }

            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API');
            }
        },
        delete: async({commit, rootGetters}, id) =>{
            let custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/wikspoints/products/active', 'DELETE', null, null, {id: id}, custom_headers);

            if(response.success && response.data) {
                commit('setAlert',
                    {   open: true,
                        variant: 'success',
                        message: 'Producto eliminado con éxito.'},
                    {root: true})
                commit('setError', false)
            }

            else if(!response.success && response.errors) {

                commit('setAlert',
                    {   open: true,
                        variant: 'danger',
                        message: 'Un error ha ocurrido intente más tarde.'},
                    {root: true})
                commit('setError', true);
            }

            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API');
            }
        }
    },
    
}