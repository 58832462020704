<template>
    <aside id="Sidebar" class="bg-light-gray" >
        <div class="row justify-content-center">
            <div class="col-12 pt-4">
                <img width="90" height="90" :src="wiks_logo" alt="">
            </div>
        </div>
        <div class="overflow-auto mt-2 p-0 d-flex flex-column align-items-center custom_height">
            <div v-for="(menu, index) in menu" :key="`menu_${index}`" style="width: 130px; z-index: 1000;">
                <div class="row" v-if="menu.submenu">
                    <div @click.stop="menu.open = !menu.open"
                        class="col-12 d-flex flex-column justify-content-center align-items-center my-3">
                        <div class="d-flex flex-center circle"
                            :class="[menu.section == section ? 'active' : '']">
                            <img  width="25" height="25" :src="menu.icon" alt="">
                        </div>
                        <span  style="width: 110px">
                            {{ menu.label }}
                        </span>
                    </div>
                    <div class="bg-dark-gray position-absolute" v-if="menu.open"
                        @mouseleave="menu.open = false"
                        style="width: 200px; left: 150px; text-align:left; font-size:14px">
                        <div style="border-top: 1px solid grey" @click.stop="$router.push({ name: submenu.route})"
                            class="d-block text-white  py-3 cursor-pointer px-0" v-for="(submenu, index) in menu.submenu" :key="`submenu-${index}`">
                            {{ submenu.label }}
                        </div>
                    </div>
                </div>
                <div @click.stop="$router.push({ name: menu.route})"
                    class="row p-0"
                    v-else>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center my-3 p-0" >
                        <div class="d-flex flex-center circle"
                            :class="[menu.section == section ? 'active' : '']">
                            <img  width="25" height="25" :src="menu.icon" alt="">
                        </div>
                        <span  style="width: 110px">
                            {{ menu.label }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3 position-absolute" style="bottom: 10px; left: 25px;">
            <div @click.stop="endsession"
                class="col-12 d-flex flex-column flex-center" >
                <div class="d-flex flex-center circle" >
                    <img  width="30" height="30" :src="logout" alt="">
                </div>
                <span>
                    Cerrar sesión
                </span>
            </div>
        </div>
    </aside>
</template>
<script setup>
    import { computed, ref } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router'
    //Images
    const wiks_logo = require('ASSETS/images/wiks_logo')

    //icons
    const logout = require('ASSETS/images/sidebar/icons/endsession')
    const dots = require('ASSETS/images/sidebar/icons/wrench')
    const wrench = require('ASSETS/images/sidebar/icons/build')
    const demography = require('ASSETS/images/sidebar/icons/demography')
    const person = require('ASSETS/images/sidebar/icons/person')
    const supervised = require('ASSETS/images/sidebar/icons/supervised_user_circle')

    //hooks
    const store = useStore()
    const router = useRouter()
    //variables

    const menu  = ref([
        {
            label: 'Puntos Wiks',
            route: 'banners',
            section: 'lealtad',
            icon: supervised,
            open: false,
            submenu: [
                {
                    label: 'Canjes',
                    route: 'points-redeems'
                },
                {
                    label: 'Categorias',
                    route: 'points-categories'
                },
                {
                    label: 'Niveles',
                    route: 'points-settings'
                },
                {
                    label: 'Productos',
                    route: 'points-products'
                }
            ]
        },
        {
            label: 'Reportes',
            icon: demography,
            section: 'reports',
            open: false,
            submenu: [
                {
                    label: 'Ventas',
                    route: 'sales'
                },
                {
                    label: 'Tajertas',
                    route: 'card-sales'
                },
                {
                    label: 'Clientes',
                    route: 'clients-report'
                },
                {
                    label: 'Productos',
                    route: 'products-report'
                },
                {
                    label: 'Colonias',
                    route: 'neighborhood'
                },
                {
                    label: 'Municipios',
                    route: 'locations'
                },
                {
                    label: 'Redenciones',
                    route: 'points-report'
                }
            ]
        },
        {
            label: 'Configuración',
            icon: wrench,
            section: 'configuration',
            open: false,
            submenu: [
                {
                    label: 'Banners',
                    route: 'banners'
                },
                {
                    label: 'Categorías',
                    route: 'categories'
                },
                {
                    label: 'Costos de envío',
                    route: 'shipment-cost'
                },
                {
                    label: 'Familias',
                    route: 'families'
                },
                {
                    label: 'Productos',
                    route: 'products'
                },
                {
                    label: 'Mensajes notificación',
                    route: 'notifications'
                },
                {
                    label: 'Nuevos productos',
                    route: 'raw-products'
                },
            ]
        },
        {
            label: 'Usuarios',
            icon: person,
            section: 'clients',
            submenu: [
                {
                    label: 'Clientes',
                    route: 'registered'
                },
                {
                    label: 'Preventa',
                    route: 'presale'
                },
                {
                    label: 'Reparto',
                    route: 'assortment'
                },
                {
                    label: 'Sin registro',
                    route: 'unregistered'
                },
                // {
                //     label: 'Movimientos',
                //     route: 'movements'
                // }
            ]
        },
    ])
    const current_index = ref(0)
    //computed
    const section = computed(() => store.getters['getSection'])

    //methods
    const endsession = () => {
        sessionStorage.removeItem('wiks@session')
        window.location.reload()
    }
</script>
<style lang="scss" scoped>
    #Sidebar {
        .custom_height {
            overflow-y: scroll;
            height: 430px;

            @media screen and (min-width: 1440px) {
                height: 70vh;
            }
        }
        .circle {
            border-radius: 50%;
            width: 50px;
            height: 50px;
            cursor: pointer;
            transition: all 0.5s;
            &:hover{
                background: white;
            }
        }
        .active {
            background: white;
        }
    }
</style>
