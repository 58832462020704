import sendRequest from "@/libs/sendRequest";

export const levels = {
    namespaced: true,
    state:{
        levels: []
    },
    getters: {
        getLevels: (state) => (state.levels),
    },
    mutations: {
        setLevels: (state, payload) => state.levels = payload,
    },
    actions: {
        list: async ({ commit, rootGetters}) => {
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('administrator/wikspoints/settings/list', 'GET', null, null, null, custom_headers)
            console.log(response, 'response list levels')

            if(response.success && response.data) {
                let levels = response.data.list ?? []

                for(let i in levels) {
                    let level = levels[i]

                    level.label = `${level.Nombre}`
                    level.id = level.ID
                }

                commit('setLevels', levels)
            }
            else if(!reponse.data && response.errors) {
                console.log(response.errors)
            }
            else if(response.success && !response.data) {
                console.log('Se perdio la conexion con la API')
            }
        }
    }
}