import sendRequest from "@/libs/sendRequest";
import {GChart as response} from "vue-google-charts/legacy";

export const clients = {
    namespaced: true,
    state: {
        client: [],
        clients: [],
        client_points_history: [],
        total_clients: null,
        search_result: null,
        history_notifications: null,
        history_wiks: null,
        cron_notifications: [],
        route_options: [],
        route_clients: [],
        error: null
    },
    getters: {
        getClient: (state) => (state.client),
        getClients: (state) => (state.clients),
        getTotalClients: (state) => (state.total_clients),
        getSearchResult: (state) => (state.search_result),
        getError: (state) => (state.error),
        getHistoryNotifications: (state) => (state.history_notifications),
        getHistoryWiks: (state) => (state.history_wiks),
        getCronNotifications: (state) => (state.cron_notifications),
        getRouteOptions: (state) => (state.route_options),
        getRouteClients: (state) => (state.route_clients),
        getClientHistory: (state) => (state.client_points_history),
    },
    mutations: {
        setClient: (state, payload) => state.client = payload,
        setClients: (state, payload) => state.clients = payload,
        setTotalClients: (state, payload) => state.total_clients = payload,
        setError: (state, payload) => state.error = payload,
        setSearchResult: (state, payload) => state.search_result = payload,
        setHistoryNotifications: (state, payload) => state.history_notifications = payload,
        setHistoryWiks: (state, payload) => state.history_wiks = payload,
        setCronNotifications: (state, payload) => state.cron_notifications = payload,
        setRouteOptions: (state, payload) => state.route_options = payload,
        setRouteClients: (state, payload) => state.route_clients = payload,
        setClientHistory: (state, payload) => state.client_points_history = payload
    },
    actions: {
        list: async ({ commit, rootGetters }, query) => {

            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/clients/select', 'GET', null, null, null, custom_headers)

            if (response.success) {
                commit('setClients', response.data)
            }
        },
        listNoRegister: async({ commit, rootGetters}) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/clients/noregister', 'GET', null, null, null, custom_headers)

            if (response.success) {
                let raw_data = response.data != null ? response.data.clientes : [];

                raw_data = raw_data.map((client) => {
                    return {
                        ID: client.ID,
                        Nombre: client.Nombre,
                        Apellido: client.Apellido,
                        ...client,
                        actions: ' '
                    }
                })

                commit('setClients', raw_data)
            }
        }, 
        scheme: async ({ commit, rootGetters }, query) => {
            const custom_headers = rootGetters.custom_headers;

            const response = await sendRequest('administrator/clients/esquema', 'GET', null, null, query, custom_headers);

            if (response.success) {

            }
        },
        app: async ({ commit, rootGetters }, query) => {
            const custom_headers = rootGetters.custom_headers;

            const response = await sendRequest(`administrator/clients/app?pagina=${query}`, 'GET', null, null, null, custom_headers);
            if (response.success) {
                commit('setClients', response.data.cliente)
            }
        },
        view: async ({ commit, rootGetters }, query) => {
            const custom_headers = rootGetters.custom_headers;

            const response = await sendRequest('administrator/clients/view', 'GET', null, null, query, custom_headers);

            if (response.success) {
                commit('setClient', response.data.usuario_datos)
            }

        },
        add: async ({ commit, rootGetters, dispatch }, body) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/clients/save', 'POST', null, body, null, custom_headers);
            console.log(response)
            if (response.success && response.data) {
                dispatch('showAlert', 'Cliente añadido con éxito.', { root: true })
                commit('setError', false)
            }

            else if(!response.success && response.errors) {

                let messages = []
                for(let error in response.errors) {
                    console.log(error)
                    if(response.errors[error].code == "wrong_or_empty_nombres" ) {
                        messages.push("El campo nombres es requerido")
                    }

                    else if(response.errors[error].code == "wrong_or_empty_apellidos" ) {
                        messages.push("El campo apellidos es requerido")
                    }

                    else if(response.errors[error].code == "wrong_or_empty_password" ) {
                        messages.push("El campo password es requerido")
                    }

                    else if(response.errors[error].code == "wrong_or_empty_username" ) {
                        messages.push("El campo username es requerido")
                    }

                    else if(response.errors[error].code == "wrong_or_empty_correo" ) {
                        messages.push("El campo correo es requerido")
                    }

                    else if(response.errors[error].code == "wrong_or_empty_telefono" ) {
                        messages.push("El campo telefono es requerido")
                    } 

                    else {
                        messages.push('Un error ha ocurrido intente más tarde')
                    }

                }
                commit('setAlert', {  
                        open: true, 
                        variant: 'danger', 
                        message: messages, 
                    },{root: true})

                commit('setError', true)
            }
        },
        update: async ({ commit, rootGetters, dispatch }, body) => {

            const custom_headers = rootGetters.custom_headers;

            const response = await sendRequest('administrator/clients/update', 'PUT', null, body, null, custom_headers);

            if (response.success) {
                dispatch('showAlert', 'Cliente editado con éxito.', { root: true })
            }

            if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == "wrong_or_empty_nombres" ) {
                        messages.push("El campo nombres es requerido")
                    }

                    else if(response.errors[error].code == "wrong_or_empty_apellidos" ) {
                        messages.push("El campo apellidos es requerido")
                    }

                    else if(response.errors[error].code == "wrong_or_empty_correo" ) {
                        messages.push("El campo correo es requerido")
                    }

                    else if(response.errors[error].code == "wrong_or_empty_telefono" ) {
                        messages.push("El campo telefono es requerido")
                    } 

                    else {
                        messages.push('Un error ha ocurrido intente más tarde')
                    }

                }

                commit('setAlert', {  
                        open: true, 
                        variant: 'danger', 
                        message: messages, 
                    },{root: true})
            }

        },
        inactive: async ({ commit, rootGetters, dispatch }, body) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/clients/deleteCliente', 'PUT', null, body, null, custom_headers);

            if (response.success) {
                dispatch('showAlert', 'Cliente eliminado con éxito.', { root: true })
            }

            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code) {
                        messages.push('Un error ha ocurrido intente más tarde')
                    }
                }

                commit('setAlert', {  
                        open: true, 
                        variant: 'danger', 
                        message: messages, 
                    },{root: true})
            }
        },
        total: async ({ commit, rootGetters }) => {
            const custom_headers = rootGetters.custom_headers;

            const response = await sendRequest('administrator/clients/total', 'GET', null, null, null, custom_headers);

            if (response.success) {
                commit('setTotalClients', response.data.total.Total)
            }
        },
        search: async({ commit, rootGetters}, query) => {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('administrator/clients/search', 'GET', null, null, query, custom_headers)

            if(response.success && response.data) {
                commit('setSearchResult', response.data.usuario_datos ?? [])
            }

            else if(!response.data && response.errors) {
                console.log(response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }
        },
        historyNotification: async ({ commit, rootGetters}, client) => {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('administrator/clients/historyNotifications', 'GET', null, null, client, custom_headers)

            if(response.success && response.data) {
                commit('setHistoryNotifications', response.data.cliente)
            }

            else if(!response.success && response.errors) {
                console.log(response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }

        },
        historyWiks: async ({ commit, rootGetters}, client) => {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('administrator/clients/historyNotificationsWiks', 'GET', null, null, client, custom_headers)

            if(response.success && response.data) {
                commit('setHistoryWiks', response.data.cliente ?? [])
            }

            else if(!response.success && response.errors) {
                console.log(response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }

        },
        cronNotifications: async({commit, rootGetters}, client) => {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('cronjobs/notifications/appNotificationHistory', 'GET', null, null, client, custom_headers)

            if(response.success && response.data) {
                commit('setCronNotifications', response.data.data ?? [])
            }

            else if(!response.success && response.errors) {
                console.log(response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }
        },
        migration: async({commit, rootGetters, dispatch}) => {
            const custom_headers = rootGetters.getHeaders;
            
            const response = await sendRequest('administrator/clients/migracionCliente',  'GET', null, null, null, custom_headers);

            if(response.success) {
                dispatch('showAlert', 'Migración realizada con exito.', { root: true })
                dispatch('list');
            }
        },
        clientRouteList: async({ commit, rootGetters }, type) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/clients/routeList', 'GET', null, null, type, custom_headers)

            if(response.success && response.data) {
                commit('setRouteOptions', response.data.data || [])
            }
        },
        listRouteClients: async({ commit, dispatch, rootGetters}, route) => {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('administrator/clients/routeClientes', 'GET', null, null, route, custom_headers)

            if(response.success && response.data) {
                commit('setRouteClients', response.data.route_clients || [])
            }
        },
        //wik points endpoints
        addPoints: async({ commit, dispatch, rootGetters}, data) => {
            const custom_headers = rootGetters.getHeaders;
            
            const response = await sendRequest('administrator/clients/points', 'PUT', null, data, null, custom_headers);
            
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    variant: 'success',
                    message: 'Puntos añadidos con éxito',
                },{root: true})
            }
             else if(response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code) {
                        messages.push('Un error ha ocurrido intente más tarde')
                    }
                }

                commit('setAlert', {
                    open: true,
                    variant: 'danger',
                    message: messages,
                },{root: true})
                commit('setError', true);
            }
        },
        clientPoints: async({commit, dispatch, rootGetters}, data) => {
            const custom_headers = rootGetters.getHeaders;
            
            const response = await sendRequest('administrator/clients/pointsHistory', 'GET', null, null, data, custom_headers);
            
            if(response.success && response.data) {
                commit('setClientHistory', response.data ?? [])
            }
        }
    }

}